import {
  Option,
  TypeaheadInputProps,
} from '@rsa-digital/evo-shared-components/components/Form/TypeaheadInput';
import { InputComponent } from '@rsa-digital/evo-shared-components/components/Form/inputTypes';
import React from 'react';
import { StyledTypeaheadComponent } from './styles';

const TypeaheadInput: InputComponent<Omit<
  TypeaheadInputProps,
  'sortOptionsCallback' | 'maxResults'
>> = ({
  id,
  options,
  value,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  noMatchesText,
  className,
  minLength = 3,
  showChevron,
  menuMaxHeight,
  ...rest
}) => {
  const alphabeticSort = (a: Option, b: Option): number => a.label.localeCompare(b.label);
  return (
    <StyledTypeaheadComponent
      id={id}
      options={options}
      value={value}
      minLength={minLength}
      maxResults={100}
      showChevron={showChevron}
      menuMaxHeight={menuMaxHeight}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      noMatchesText={noMatchesText}
      className={className}
      sortOptionsCallback={alphabeticSort}
      {...rest}
    />
  );
};

export default TypeaheadInput;
