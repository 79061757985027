import TypeheadComponent from '@rsa-digital/evo-shared-components/components/Form/TypeaheadInput';
import { StyledMenu } from '@rsa-digital/evo-shared-components/components/Form/TypeaheadInput/styles';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledTypeaheadComponent = styled(TypeheadComponent)`
  ${StyledMenu} {
    margin-top: ${spacing(1)};
  }
`;
