import { initialPet, usePetsDetails } from 'state/formData/petsDetails';
import { initialExcessPercentageConfirmation } from 'state/formData/quoteOptions';
import { addItem, removeItem } from './arrayHelpers';
import { useCurrentQuote, useUpdateQuoteOptions } from './useCurrentQuote';

const useUpdateNumberOfPets = (): {
  addPet: () => void;
  removePet: (index: number) => number;
} => {
  const [petsDetails, updatePetsDetails] = usePetsDetails();
  const { quoteOptions } = useCurrentQuote();
  const updateQuoteOptions = useUpdateQuoteOptions();

  const addPet = (): void => {
    const updatedExcessPercentageConfirmations = addItem(
      quoteOptions.excessPercentageConfirmations,
      initialExcessPercentageConfirmation
    );

    updatePetsDetails(addItem(petsDetails, initialPet));
    updateQuoteOptions({
      excessPercentageConfirmations: updatedExcessPercentageConfirmations,
    });
  };

  const removePet = (index: number): number => {
    const updatedExcessPercentageConfirmations = removeItem(
      quoteOptions.excessPercentageConfirmations,
      index
    );

    updatePetsDetails(removeItem(petsDetails, index));
    updateQuoteOptions({
      excessPercentageConfirmations: updatedExcessPercentageConfirmations,
    });

    return updatedExcessPercentageConfirmations.length;
  };

  return { addPet, removePet };
};

export default useUpdateNumberOfPets;
